import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { formatPrice } from '@spotahome/soyuz-currency';

import { useSoyuzExperiment } from '@spotahome/soyuz/client';

import { useDeviceContext } from '@spotahome/ui-library';

import AdUrlService from '../../utils/ad-url-service';
import { useGoogleMapsScript } from '../../utils/google-maps-script';
import { useHighlightMarkersContext } from '../../context/HighlightMarkersContext';

import MapsAPI from '../../LEGACY/javascripts/ui/map/maps-api';

import mapStyles from './mapStyles';

import './Map.scss';

const MAP_PADDING = 40;
const MIN_ZOOM = 1;
const MAX_ZOOM = 20;

const Map = ({
  pointOfInterest = null,
  showPrice = true,
  showClusters = false,
  onZoomChange = () => {},
  defaultZoom = 11,
  onMapDrag = () => {},
  centerCoordinates = {
    lat: 0,
    lng: 0
  },
  mapId,
  markerList,
  currencyIsoCode,
  onIdle,
  onMarkerClicked,
  onMapDidMount,
  onMapWillUnmount
}) => {
  const DesktopMapHomecardExp = useSoyuzExperiment('DesktopMapHomecard');

  const map = useRef(null);
  const prevMarkers = useRef(markerList);
  const { deviceType } = useDeviceContext();
  const getDesktopMapHomecardExp = () =>
    deviceType === 'desktop' &&
    DesktopMapHomecardExp.get('behaviour') === 'variant';

  const { handleMarkerClicked, homecardMouseEnterId } =
    useHighlightMarkersContext();

  const TRANSPARENT_MARKER_PATH =
    'https://static.spotahome.com/map/marker-price-transparent.svg';

  const decorateMakerWithLabel = marker => {
    return {
      ...marker,
      label:
        showPrice && marker
          ? `${formatPrice(marker.minimumPrice, currencyIsoCode)}`
          : null
    };
  };

  const centerMap = () => {
    const mapCenter =
      typeof window !== 'undefined' &&
      AdUrlService.getQueryParamValue('mapCenter');

    if (mapCenter) {
      const mapCenterParts = mapCenter.split(',');
      map.current.setCenter({
        lat: parseFloat(mapCenterParts[0]),
        lng: parseFloat(mapCenterParts[1])
      });
    } else {
      map.current.setCenter({
        lat: parseFloat(centerCoordinates.lat),
        lng: parseFloat(centerCoordinates.lng)
      });
    }
  };

  const handleClusterClicked = coordinates => {
    map.current.setCenter({ lat: coordinates.lat, lng: coordinates.lng });
    map.current.increaseZoom();
  };

  const handleMapLoad = () => {
    const mapCenter =
      typeof window !== 'undefined' &&
      AdUrlService.getQueryParamValue('mapCenter');
    const initializePosition = mapCenter === null;
    map.current = new MapsAPI(
      mapId,
      {
        zoom: defaultZoom,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        padding: MAP_PADDING,
        transitLayer: true,
        showClusters,
        styles: mapStyles,
        onZoomChange,
        onIdle
      },
      {
        onMarkerClick: markerId => {
          handleMarkerClicked(markerId);
          onMarkerClicked(markerId);
        },
        onClusterClick: handleClusterClicked,
        markerIcon: TRANSPARENT_MARKER_PATH
      },
      onMapDrag,
      initializePosition,
      deviceType,
      getDesktopMapHomecardExp
    );

    centerMap();

    if (onMapDidMount) {
      onMapDidMount(map.current);
    }

    map.current.addMarkers(markerList.map(decorateMakerWithLabel));

    if (pointOfInterest) {
      map.current.addPointOfInterest(pointOfInterest);
    } else {
      map.current.removePointOfInterest();
    }
  };

  useGoogleMapsScript(handleMapLoad);

  useEffect(() => {
    return () => {
      if (map.current) {
        map.current.removeMapListeners();
      }
      onMapWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (homecardMouseEnterId && map.current) {
      map.current.activeMarker(homecardMouseEnterId);
    }
  }, [homecardMouseEnterId]);

  useEffect(() => {
    if (!isEqual(prevMarkers.current, markerList) && map.current) {
      map.current.updateMarkers(
        prevMarkers.current,
        markerList.map(decorateMakerWithLabel),
        pointOfInterest
      );
      prevMarkers.current = markerList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerList]);

  useEffect(() => {
    if (!map.current) {
      return;
    }

    if (pointOfInterest) {
      map.current.addPointOfInterest(pointOfInterest);
    } else {
      map.current.removePointOfInterest();
    }
  }, [pointOfInterest]);

  return <div className="l-map" id={mapId} data-cy="themap" />;
};

Map.propTypes = {
  mapId: PropTypes.string.isRequired,
  centerCoordinates: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }),
  defaultZoom: PropTypes.number,
  markerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pointOfInterest: PropTypes.object,
  onMarkerClicked: PropTypes.func.isRequired,
  onMapDidMount: PropTypes.func.isRequired,
  onMapWillUnmount: PropTypes.func.isRequired,
  onIdle: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func,
  currencyIsoCode: PropTypes.string.isRequired,
  showPrice: PropTypes.bool,
  showClusters: PropTypes.bool,
  onMapDrag: PropTypes.func
};

export default Map;
